import { Columnz, DataTablez } from "@/components/data_table";
import { LoadDialog } from "@/components/data_table/Dialogz";
import {
  Dropdownz,
  GridForm,
  HeaderListForm,
  Inputz,
} from "@/components/data_table/FormList";
import { useCountListSearchingPb, useListSearchingPb } from "../utils";
import { useGetParams } from "@/hooks";
import { useState } from "react";
import { useListProjectForm } from "@/modules/project/utils";
const Header = ({ setParams, setFilter, filter }) => {
  const list_projectForm = useListProjectForm();
  return (
    <GridForm setParams={setParams} filter={filter} setFilter={setFilter}>
      <Inputz
        value={filter?.key_search}
        placeholder="Tìm kiếm theo tên phòng ban"
        onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
      />
      <Dropdownz
        value={filter?.cb_id}
        onChange={(e) => setFilter({ ...filter, cb_id: e.target.value })}
        options={list_projectForm}
        className=" col-6"
        placeholder="Chọn dự án"
        optionLabel={(option) => `${option.cb_title}  (${option.cb_code})`}
        optionValue="cb_id"
        filter
        style={{ lineHeight: "30px" }}
      ></Dropdownz>
    </GridForm>
  );
};
const SearchDepartment = () => {
  const initParam = useGetParams();
  const [params, setParams] = useState(initParam);
  const [filter, setFilter] = useState();
  const [visible, setVisible] = useState(false);
  const list_department = useListSearchingPb({
    status: undefined,
    ...params,
    first: undefined,
  });
  const totalRecords = useCountListSearchingPb({
    status: undefined,
    ...params,
    first: undefined,
  });
  return (
    <>
      <div className="card mx-auto ">
        <HeaderListForm title="Tra cứu phòng ban" />
        <Header setParams={setParams} setFilter={setFilter} filter={filter} />
        <LoadDialog visible={visible} />
        <DataTablez
          title="phê duyệt"
          value={list_department}
          totalRecords={totalRecords}
          params={params}
          setParams={setParams}
        >
          <Columnz header="ID phòng ban" field="gb_id" />
          <Columnz header="Tên phòng ban" field="gb_title" />
          <Columnz header="Mã phòng ban" field="reference_code" />
        </DataTablez>
      </div>
    </>
  );
};
export default SearchDepartment;
