import { LoadDialog } from "@/components/data_table/Dialogz";
import { HeaderListForm } from "@/components/data_table/FormList";
import { useState } from "react";
import {
  useCountListDetailSheet,
  useGetListDetailSheet,
  useGetListSheet,
  useListProjectForm,
} from "../utils";
import { useGetParams } from "@/hooks";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "@/uiCore";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Columnz, DataTablez, StatusBody } from "@/components/data_table";
import {
  DropdownForm,
  FormUpdateDialog,
  InputForm,
} from "@/components/data_table/FormUpdate";
import { updateActiveDetailSheet, updateDetailSheet } from "../api";

const DetailSheetList = () => {
  const initParam = useGetParams();
  const [params, setParams] = useState(initParam);
  const [visible, setVisible] = useState(false);
  const [infos, setInfos] = useState();
  const [visibleUpdate, setVisibleUpdate] = useState(false);
  const location = useLocation();
  const path_id = location?.pathname?.split("/")?.[3];
  const list_sheet = useGetListSheet({
    status: undefined,
    ...params,
    first: undefined,
  });
  const ParseLinkSheet = (id) => {
    const sheet_id = list_sheet?.find(
      (e) => e?.id == Number(path_id)
    )?.sheet_id;
    const updatedUrl = sheet_id?.replace(/gid=\d+/g, `gid=${id}`);
    return updatedUrl;
  };
  const list_detail_sheet = useGetListDetailSheet({
    status: undefined,
    ...params,
    first: undefined,
    id_form: path_id,
  });
  const totalRecords = useCountListDetailSheet({
    status: undefined,
    ...params,
    first: undefined,
    id_form: path_id,
  });
  const list_projectForm = useListProjectForm(params);
  const handleDataUpdate = () => {
    if (!infos?.campaign_name) return "Bạn chưa nhập tên chiến dịch";
    if (!infos?.category_id) return "Bạn chưa chọn dự án";
    return infos;
  };
  const onHidingUpdate = () => {
    setInfos({});
    setVisibleUpdate(false);
  };
  const ParseCategory = (category_id) => {
    const category = list_projectForm?.find((e) => e.cb_id == category_id);
    if (category?.cb_title) return category?.cb_title;
    else return "Không có dự án";
  };
  const title = list_sheet?.find((e) => e?.id == Number(path_id))?.title;
  const ParseDate = (date) => {
    const date_only = String(date)?.substring(0, 10)?.split("-");
    return `${date_only[2]}-${date_only[1]}-${date_only[0]}`;
  };
  const ParseTime = (date) => {
    let dateValue = new Date(date);
    const time = `${dateValue?.getHours()}:${dateValue?.getMinutes()}:${dateValue?.getSeconds()}`;
    const date_only = String(date)?.substring(0, 10)?.split("-");
    return `${time} ${date_only[2]}-${date_only[1]}-${date_only[0]}`;
  };

  const handleUpdate = (e) => {
    setInfos({
      ...infos,
      category_id: e?.category_id,
      id_sheet: e?.id_sheet,
      id: e?.id,
      name: e?.name,
      campaign_name: e?.campaign_name,
      campaign_id: e?.campaign_id,
      website: e?.website !== "null" ? e?.website : "",
      landing_page: e?.landing_page !== "null" ? e?.landing_page : "",
    });
    setVisibleUpdate(true);
  };
  const navigate = useNavigate();
  const handleReturn = () => {
    navigate("/sheet_list");
  };
  return (
    <>
      <div className="card mx-auto ">
        <HeaderListForm
          title={`Quản lý danh sách trang tính : ${title ? title : ""}`}
        />
        <LoadDialog visible={visible} />
        <ConfirmDialog />

        <FormUpdateDialog
          visible={visibleUpdate}
          setVisible={setVisibleUpdate}
          title="Cập nhật thông tin trang tính"
          checkId={Number(visibleUpdate)}
          setParams={setParams}
          actions={{ update: updateDetailSheet }}
          handleData={handleDataUpdate}
          width={"1400px"}
          onHide={() => onHidingUpdate()}
        >
          <div className="grid ">
            <div className="col-6">
              <InputForm
                value={infos?.id_sheet}
                onChange={(e) =>
                  setInfos({ ...infos, id_sheet: e.target.value })
                }
                readOnly
                label="ID trang tính"
              />
            </div>
            <div className="col-6">
              <InputForm
                value={infos?.name}
                readOnly
                onChange={(e) => setInfos({ ...infos, name: e.target.value })}
                label="Tên trang tính"
              />
            </div>
          </div>
          <div className="grid ">
            <div className="col-6">
              <InputForm
                value={infos?.campaign_id}
                onChange={(e) =>
                  setInfos({ ...infos, campaign_id: e.target.value })
                }
                label="Id chiến dịch (nếu có)"
              />
            </div>
            <div className="col-6">
              <InputForm
                value={infos?.campaign_name}
                onChange={(e) =>
                  setInfos({ ...infos, campaign_name: e.target.value })
                }
                label="Tên chiến dịch(*)"
              />
            </div>
          </div>
          <div className="grid mt-2 ">
            <div className="col-6">
              <InputForm
                value={infos?.website}
                onChange={(e) =>
                  setInfos({ ...infos, website: e.target.value })
                }
                label="Website/LandingPage (url)"
              />
            </div>
            <div className="col-6">
              <DropdownForm
                showClear={false}
                value={infos?.category_id}
                onChange={(e) =>
                  setInfos({ ...infos, category_id: e.target.value })
                }
                options={list_projectForm}
                optionLabel="cb_title"
                optionValue="cb_id"
                label="Dự án (*)"
              />
            </div>
          </div>
          <div style={{ height: "20vh" }}></div>
        </FormUpdateDialog>
        <DataTablez
          title="phê duyệt"
          value={list_detail_sheet}
          totalRecords={totalRecords}
          params={params}
          setParams={setParams}
        >
          <Columnz header="ID trang tính" field="id_sheet" />
          <Columnz
            header="Tên trang tính"
            body={(e) => (
              <a
                href={`${ParseLinkSheet(e?.id_sheet)}`}
                target="_blank"
                rel="Sheet URL"
              >
                {e?.name}
              </a>
            )}
          />
          {/* <Columnz
            header="ID Chiến dịch"
            body={(e) => <span>{e?.campaign_id ? e?.campaign_id : "N/A"}</span>}
          /> */}
          <Columnz
            header="Chiến dịch"
            body={(e) => (
              <span>{e?.campaign_name ? e?.campaign_name : "N/A"}</span>
            )}
          />
          <Columnz
            header="Website/LandingPage"
            body={(e) => (
              <>
                {!e?.website ? (
                  "N/A"
                ) : (
                  <a href={`${e.website}`} target="_blank" rel="Website">
                    <span>
                      {String(e?.website)?.length <= 30
                        ? e?.website
                        : `${String(e?.website)?.substring(0, 30)}...`}
                    </span>
                  </a>
                )}
              </>
            )}
          />
          <Columnz header="Dự án" body={(e) => ParseCategory(e?.category_id)} />
          <Columnz header="Ngày tạo" body={(e) => ParseDate(e?.created_at)} />
          <Columnz
            header="Cập nhật gần nhất"
            body={(e) => ParseTime(e?.updated_at)}
          />
          <Columnz
            body={(e) => (
              <Link to={`/sheet_list/sheet_detail/${e?.id}`}>
                {" "}
                <Button
                  type="button"
                  icon="pi pi-calendar-clock"
                  rounded
                  outlined
                  className={"mr-1 mx-auto flex"}
                  severity={"info"}
                />
              </Link>
            )}
            header="Lịch phân bổ"
          />
          <Columnz
            body={(e) => (
              <div className="flex flex-row">
                <Button
                  onClick={() => handleUpdate(e)}
                  type="button"
                  icon="pi pi-wrench"
                  rounded
                  outlined
                  className={" mx-auto flex"}
                />
              </div>
            )}
            header="Set up"
          />
          <Columnz
            header="Active"
            body={(e) =>
              StatusBody({
                e,
                updateAction: updateActiveDetailSheet,
                setParams: setParams,
                isAllow: Boolean(
                  e?.category_id !== undefined &&
                    e?.category_id !== null &&
                    e?.category_id !== 0 &&
                    e?.campaign_name &&
                    e?.campaign_name !== ""
                ),
                params: params,
              })
            }
          />
        </DataTablez>
        <div className="flex flex-row-reverse m-3">
          <Button severity="danger" onClick={() => handleReturn()}>
            Quay về
          </Button>
        </div>
      </div>
    </>
  );
};
export default DetailSheetList;
