import Message from "@/modules/message/screens/message";
import ErrorPath from "../modules/error/error";
import { Home } from "../modules/home";
import { Login } from "../modules/login";
import Divide from "@/modules/project/screen/divide";
import ProjectOvervew from "@/modules/project/screen/ProjectOverview";
import PageList from "@/modules/project/screen/PageList";
import UpdatePage from "@/modules/project/screen/UpdatePage";
import PageForm from "@/modules/project/screen/PageForm";
import ProjectList from "@/modules/project/screen/ProjectList";
import ReportDay from "@/modules/report/screen/ReportDay";
import Report from "@/modules/report/screen/Report";
import ReportProject from "@/modules/report/screen/ReportProject";
import SheetList from "@/modules/project/screen/SheetList";
import SearchDepartment from "@/modules/search/screens/SeachDepartment";
import DetailSheetList from "@/modules/project/screen/DetailSheetList";
import UpdateSheet from "@/modules/project/screen/UpdateSheet";
import UpdatePageNew from "@/modules/project/screen/UpdatePageNew";
import UpdateSheetNew from "@/modules/project/screen/UpdateSheetNew";
import SearchPerDay from "@/modules/search/screens/SearchPerDay";
export const routes = [
  { path: "/", component: Home, layout: true },
  { path: "/home", component: Home, layout: true },
  { path: "/divide", component: Divide, layout: true },
  // { path: "/message", component: Message, layout: true },
  { path: "/login", component: Login, public: true },
  { path: "/project_overview", component: ProjectOvervew, layout: true },
  { path: "/page_list", component: PageList, layout: true },
  { path: "/report", component: Report, layout: true },
  { path: "/report_day", component: ReportDay, layout: true },
  { path: "/report_project", component: ReportProject, layout: true },
  { path: "/assign_category", component: ProjectList, layout: true },
  { path: "/sheet_list", component: SheetList, layout: true },
  { path: "/search_department", component: SearchDepartment, layout: true },
  { path: "/search_per_day", component: SearchPerDay, layout: true },
  { path: "/page_list/detail/:id", component: UpdatePage, layout: true },
  { path: "/page_list_new/detail/:id", component: UpdatePageNew, layout: true },
  // {
  //   path: "/sheet_list/sheet_detail/:id",
  //   component: UpdateSheet,
  //   layout: true,
  // },
  {
    path: "/sheet_list/sheet_detail/:id",
    component: UpdateSheetNew,
    layout: true,
  },
  { path: "/sheet_list/detail/:id", component: DetailSheetList, layout: true },
  { path: "/page_list/page_detail/:id", component: PageForm, layout: true },
];
export const errorPage = { path: "*", component: ErrorPath };
export const accessDeniedPage = { path: "*", component: ErrorPath };
