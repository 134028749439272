import { Columnz, DataTablezD } from "@/components/data_table";
import { useGetParams } from "@/hooks";
import { useState } from "react";
import {
  Calendarz,
  GridForm,
  HeaderListForm,
} from "@/components/data_table/FormList";
import { useListLeadperProject, useListPbReport } from "../utils";
import { expListLeadperProject } from "../api";
const Header = ({ setParams, setFilter, filter }) => {
  return (
    <GridForm setParams={setParams} filter={filter} setFilter={setFilter}>
      <Calendarz
        className="mt-2 col-4"
        value={filter?.dates}
        onChange={(e) => setFilter({ ...filter, dates: e.value })}
        placeholder={"Chọn khoảng thời gian"}
        selectionMode="range"
      />
      <div className="col-5"></div>
    </GridForm>
  );
};
const ReportProject = () => {
  const initParam = useGetParams();
  const [params, setParams] = useState(initParam);
  const [filter, setFilter] = useState();
  const list_pb_report = useListPbReport();
  const list_data = useListLeadperProject({
    from: params?.from,
    to: params?.to,
  });
  const handleName = (project) => {
    return project !== null ? project : "Không dự án";
  };
  return (
    <div className="card">
      <HeaderListForm title="Báo cáo theo Dự án" />
      <Header setParams={setParams} setFilter={setFilter} filter={filter} />
      <div className="flex flex-column">
        <div className="col-12 mx-auto flex">
          <DataTablezD
            style={{ width: "100%" }}
            title="báo cáo theo dự án"
            value={list_data}
            totalRecords={list_data?.length == 0 ? 0 : list_data?.length - 1}
            params={params}
            setParams={setParams}
            numRows={list_data?.length}
            basePermissions={["export"]}
            noCheckTime
            headerInfo={{
              exportApi: () => expListLeadperProject(params),
            }}
          >
            <Columnz body={(e) => handleName(e?.project)} header="Dự án" />
            {list_pb_report?.map((e) => (
              <Columnz field={e?.gb_title} header={e?.gb_title} />
            ))}
            <Columnz field="Không phòng ban" header="Không phòng ban" />
            <Columnz field="total" header="Tổng" />
          </DataTablezD>
        </div>
      </div>
    </div>
  );
};
export default ReportProject;
