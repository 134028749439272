import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";

const getMax = (b) => {
  let max = b[0];
  for (let i = 1; i < b.length; i++) {
    if (b[i] > max) {
      max = b[i];
    }
  }
  return max;
};
export const StackedBarPerDay = (props) => {
  const { data_day, data_list_day, list_pb, label } = props;
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const colors = [
    "rgba(255, 99, 132, 2)",
    "rgba(54, 162, 235, 2)",
    "rgba(255, 206, 86, 2)",
    "rgba(75, 192, 192, 2)",
    "rgba(153, 102, 255, 2)",
    "rgba(255, 159, 64, 2)",
    "rgba(142, 68, 173, 2)",
    "rgba(39, 174, 96, 2)",
    "rgba(230, 126, 34, 2)",
    "rgba(211, 84, 0, 2)",
  ];
  const a = [];
  for (let i = 0; i < data_day.length; i++) {
    a.push(data_day?.[i].value);
  }
  const b = [];
  for (let j = 0; j < a[0]?.length; j++) {
    let sum = 0;
    for (let i = 0; i < a.length; i++) {
      sum += a[i][j];
    }
    b.push(sum);
  }

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      labels: list_pb,
      datasets: data_day
        ?.sort(
          (a, b) =>
            new Date(a.day.split("/").reverse().join("-")) -
            new Date(b.day.split("/").reverse().join("-"))
        )
        ?.map((e, index) => ({
          type: "bar",
          label: e?.day,
          backgroundColor: colors[index],
          data: e.value,
          barThickness: data_day?.length < 10 ? 50 : 20,
        })),
    };

    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,

      plugins: {
        tooltips: {
          mode: "index",
          intersect: false,
        },
        legend: {
          labels: {
            color: textColor,
          },
          position: "bottom",
        },
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
        y: {
          stacked: true,
          ticks: {
            color: textColorSecondary,
            stepSize: getMax(b) < 10 ? 1 : 10,
          },
          grid: {
            color: surfaceBorder,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, [data_day, list_pb]);

  return (
    <div className="card">
      <span className="font-bold text-xl ml-4">{label}</span>
      <div className="mt-3">
        <Chart type="bar" data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};
export const StackedBarPerProject = (props) => {
  const { data_day, list_project, label } = props;
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const colors = [
    "rgba(255, 99, 132, 2)",
    "rgba(54, 162, 235, 2)",
    "rgba(255, 206, 86, 2)",
    "rgba(75, 192, 192, 2)",
    "rgba(153, 102, 255, 2)",
    "rgba(255, 159, 64, 2)",
    "rgba(142, 68, 173, 2)",
    "rgba(39, 174, 96, 2)",
    "rgba(230, 126, 34, 2)",
    "rgba(211, 84, 0, 2)",
  ];
  let list = [];
  for (let i = 0; i < list_project.length; i++) {
    list.push(Number(list_project[i]?.total));
  }

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      labels: list_project?.map((e) => e?.project),
      datasets: data_day?.map((e, index) => ({
        type: "bar",
        label: e?.pb,
        backgroundColor: colors[index],
        data: e.value,
        barThickness: data_day?.length < 10 ? 50 : 20,
      })),
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        tooltips: {
          mode: "index",
          intersect: false,
        },
        legend: {
          labels: {
            color: textColor,
          },
          position: "bottom",
        },
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
        y: {
          stacked: true,
          ticks: {
            color: textColorSecondary,
            stepSize: getMax(list) < 10 ? 1 : 10,
          },
          grid: {
            color: surfaceBorder,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, [data_day, list_project]);

  return (
    <div className="card">
      <span className="font-bold text-xl ml-4">{label}</span>
      <div className="mt-3">
        <Chart type="bar" data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};
