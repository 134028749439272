import {
  DropdownForm,
  FormUpdateDialog,
  InputForm,
} from "@/components/data_table/FormUpdate";
import { useState } from "react";
import { addLeadHand } from "../api";
import { useListDepartment, useListProjectForm } from "../utils";

const AddLead = (props) => {
  const { leadVisible, setLeadVisible, setParams, list_source } = props;
  const [infos, setInfos] = useState({});
  const handleData = () => {
    const info = { ...infos };
    if (!info?.category_id) return "Bạn chưa chon dự án";
    if (!info?.pb_id) return "Bạn chưa chon phòng ban";
    return info;
  };
  const list_department = useListDepartment({
    status: undefined,
  });
  const list_projectForm = useListProjectForm();

  return (
    <>
      <FormUpdateDialog
        title="THÊM MỚI KHÁCH HÀNG"
        visible={leadVisible}
        setVisible={setLeadVisible}
        refreshObjects={[setInfos]}
        handleData={handleData}
        actions={{ add: addLeadHand }}
        setParams={setParams}
      >
        <div className="grid">
          <div className="col-6">
            <InputForm
              value={infos.name}
              onChange={(e) => setInfos({ ...infos, name: e.target.value })}
              label="Tên khách hàng (*)"
              required
            />
          </div>
          <div className="col-6">
            <InputForm
              value={infos.email}
              onChange={(e) => setInfos({ ...infos, email: e.target.value })}
              label="Email (*)"
              required
            />
          </div>
          <div className="col-6">
            <InputForm
              value={infos.phone}
              onChange={(e) => setInfos({ ...infos, phone: e.target.value })}
              label="Số điện thoại (*)"
              required
            />
          </div>
          <div className="col-6">
            <DropdownForm
              value={infos?.source}
              onChange={(e) => setInfos({ ...infos, source: e.target.value })}
              options={list_source}
              optionLabel="source"
              optionValue="source"
              label="Nguồn (*)"
              className="w-full"
              required
            />
          </div>
          <div className="col-6">
            <DropdownForm
              value={infos?.category_id}
              onChange={(e) =>
                setInfos({
                  ...infos,
                  category_id: e.target.value ? e.target.value : "",
                })
              }
              options={list_projectForm}
              optionLabel={(option) =>
                `${option.cb_title}  (${option.cb_code})`
              }
              optionValue="cb_id"
              label="Dự án (*)"
            />
          </div>
          <div className="col-6">
            <DropdownForm
              value={infos?.pb_id}
              onChange={(e) =>
                setInfos({
                  ...infos,
                  pb_id: e.target.value ? e.target.value : "",
                })
              }
              options={list_department}
              optionLabel={(option) => `${option.gb_title}  (${option.gb_id})`}
              optionValue="gb_id"
              label="Phòng ban (*)"
            />
          </div>
        </div>
        <div style={{ height: "25vh" }}></div>
      </FormUpdateDialog>
    </>
  );
};
export default AddLead;
