import { Button } from "primereact/button";
import error from "@/image/err.jpg";
const ErrorPath = () => {
  const handleReturn = () => {
    window.history.back();
  };
  return (
    // <div class="flex align-items-center justify-content-center">
    //   <div className="flex flex-column">
    //     <span className="fadein animation-duration-1000 animation-iteration-infinite font-bold text-red-500 text-8xl		">
    //       URL hiện không tồn tại
    //     </span>
    //     <Button
    //       onClick={handleReturn}
    //       className="font-bold w-8 text-4xl mx-auto"
    //     >
    //       Trở về trang trước đó
    //     </Button>
    //   </div>
    // </div>
    <div
      className="fadein animation-duration-1000 h-screen flex align-items-center justify-content-center"
      style={{ backgroundColor: "#caeceb", position: "relative" }}
    >
      <Button
        onClick={handleReturn}
        severity="info"
        className="font-bold  text-3xl mx-auto"
        style={{ position: "absolute", top: "0", left: "0" }}
      >
        Trở về trang trước đó
      </Button>
      <div className="w-8">
        <img src={error} style={{ width: "100%" }}></img>
      </div>
    </div>
  );
};
export default ErrorPath;
