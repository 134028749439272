import { useState } from "react";
import {
  useCountListFormbyPage,
  useListFormbyPage,
  useListProjectForm,
} from "../utils";
import { useGetParams } from "../../../hooks/useGetParams";
import { Columnz, DataTablez } from "@/components/data_table";
import { HeaderListForm } from "@/components/data_table/FormList";
import { LoadDialog } from "@/components/data_table/Dialogz";
import { Button } from "@/uiCore";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DropdownForm,
  FormUpdateDialog,
  InputForm,
} from "@/components/data_table/FormUpdate";
import { updateProjectCampaign } from "../api";
const PageForm = () => {
  const initParam = useGetParams();
  const [params, setParams] = useState(initParam);
  const [visible, setVisible] = useState(false);
  const [infos, setInfos] = useState();
  const locaion = useLocation().pathname;
  const navigate = useNavigate();
  const page_id = locaion?.split("/")?.[3];
  const list_page = useListFormbyPage({
    status: undefined,
    ...params,
    first: undefined,
    project_id_ad: page_id,
  });
  const totalRecords =
    useCountListFormbyPage({
      status: undefined,
      ...params,
      first: undefined,
      project_id_ad: page_id,
    }) || 0;
  const [showDialog, setShowDialog] = useState(false);
  const [campaignName, setCampaignName] = useState();
  const list_projectForm = useListProjectForm();
  const handleData = () => {
    if (!infos?.category_id) return "Bạn chưa chọn dự án";
    return infos;
  };
  const handleReturn = () => {
    navigate("/page_list");
  };
  return (
    <div>
      {showDialog && (
        <FormUpdateDialog
          visible={showDialog}
          setVisible={setShowDialog}
          title="Phân chia dự án"
          checkId={Number(showDialog)}
          setParams={setParams}
          actions={{ update: updateProjectCampaign }}
          handleData={handleData}
        >
          <div className="grid grid-form" style={{ marginBottom: "20vh" }}>
            <div className="col-6">
              <InputForm label="Tên page" value={campaignName} readOnly />
            </div>
            <div className="col-6 ">
              <DropdownForm
                value={infos?.category_id}
                onChange={(e) =>
                  setInfos({ ...infos, category_id: e.target.value })
                }
                options={list_projectForm}
                optionLabel="cb_title"
                optionValue="cb_id"
                label="Dự án"
              />
            </div>
          </div>
        </FormUpdateDialog>
      )}
      <div className="card mx-auto ">
        <HeaderListForm title="Danh sách chiến dịch " />
        <LoadDialog visible={visible} />
        <DataTablez
          title="phê duyệt"
          value={list_page}
          totalRecords={totalRecords}
          params={params}
          setParams={setParams}
        >
          <Columnz header="Id Chiến dịch" field="id_form" />
          <Columnz header="Tên Chiến dịch" field="name" />
        </DataTablez>
        <div className="flex flex-row-reverse mt-3">
          <Button severity="danger" onClick={() => handleReturn()}>
            Quay về
          </Button>
        </div>
      </div>
    </div>
  );
};
export default PageForm;
