import { getData, postData } from "@/lib/request";

export const listPbReport = (params) =>
  getData("web/project/listPbReport", params);
export const listLeadperPb = (params) =>
  getData("web/project/listLeadperPb", params);
export const listLeadperDay = (params) =>
  getData("web/project/listLeadperDay", params);
export const listLeadperDaySearch = (params) =>
  getData("web/project/listLeadperDaySearch", params);
export const staticLeadperDay = (params) =>
  getData("web/project/staticLeadperDay", params);
export const staticLeadperPb = (params) =>
  getData("web/project/staticLeadperPb", params);
export const listLeadperProject = (params) =>
  getData("web/project/listLeadperProject", params);
export const expListLeadperProject = (params) =>
  getData("dev/expListLeadperProject", params, true);
export const expListLeadperDay = (params) =>
  getData("dev/expListLeadperDay", params, true);
export const expListLeadReport = (params) =>
  getData("dev/expListLeadReport", params, true);
export const importQuestion = (params) =>
  postData("dev/importQuestion ", params, true);
