import { Columnz, DataTablezD } from "@/components/data_table";
import { useGetParams } from "@/hooks";
import { useState } from "react";
import {
  Calendarz,
  Dropdownz,
  GridForm,
  HeaderListForm,
} from "@/components/data_table/FormList";
import { useListLeadperPb, useListPbReport } from "../utils";
import { expListLeadReport } from "../api";
const Header = ({ setParams, setFilter, filter }) => {
  const list_pb_report = useListPbReport();
  return (
    <GridForm setParams={setParams} filter={filter} setFilter={setFilter}>
      <Dropdownz
        value={filter?.category_id}
        onChange={(e) => setFilter({ ...filter, category_id: e.target.value })}
        options={[
          ...list_pb_report,
          { gb_id: 111111111, gb_title: "Không phòng ban" },
        ]}
        className="mt-2 col-5"
        placeholder="Chọn phòng ban"
        optionLabel="gb_title"
        optionValue="gb_id"
        filter
        style={{ lineHeight: "30px" }}
      ></Dropdownz>
      <Calendarz
        className="mt-2 col-4"
        value={filter?.dates}
        onChange={(e) => setFilter({ ...filter, dates: e.value })}
        placeholder={"Chọn khoảng thời gian"}
        selectionMode="range"
      />
    </GridForm>
  );
};
const Report = () => {
  const initParam = useGetParams();
  const [params, setParams] = useState(initParam);
  const [filter, setFilter] = useState();
  const list_data = useListLeadperPb({
    category_id: params?.category_id,
    from: params?.from,
    to: params?.to,
  });
  const handleName = (project) => {
    return project !== null ? project : "Không dự án";
  };
  return (
    <div className="card">
      <HeaderListForm title="Báo cáo tổng quát" />
      <Header setParams={setParams} setFilter={setFilter} filter={filter} />
      <div className="flex flex-column">
        <div className="col-12 mx-auto flex">
          <DataTablezD
            style={{ width: "100%" }}
            title="báo cáo tổng quát"
            value={list_data}
            params={params}
            totalRecords={list_data?.length == 0 ? 0 : list_data?.length - 1}
            setParams={setParams}
            numRows={list_data?.length}
            basePermissions={["export"]}
            noCheckTime
            headerInfo={{
              exportApi: () => expListLeadReport(params),
            }}
          >
            <Columnz body={(e) => handleName(e?.project)} header="Tên dự án" />
            <Columnz field="total" header="Số lượng khách hàng" />
          </DataTablezD>
        </div>
      </div>
    </div>
  );
};
export default Report;
