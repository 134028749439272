import { useState } from "react";
import {
  useCategoryDetail,
  useCountListAssignCategory,
  useListAssignCategory,
  useListPrioritize,
  useListProjectForm,
} from "../utils";
import { useGetParams } from "../../../hooks/useGetParams";
import { Columnz, DataTablez } from "@/components/data_table";
import {
  Dropdownz,
  GridForm,
  HeaderListForm,
  Inputz,
} from "@/components/data_table/FormList";

import { LoadDialog } from "@/components/data_table/Dialogz";
import { Button } from "@/uiCore";
import { Link, useNavigate } from "react-router-dom";
import {
  DropdownForm,
  FormUpdateDialog,
  InputForm,
} from "@/components/data_table/FormUpdate";
import {
  deletePrioritizeProject,
  prioritizeProject,
  updateProjectCampaign,
} from "../api";
import ImportService from "./ImportService";
import { importQuestion } from "@/modules/report/api";
const Header = ({ setParams, setFilter, filter, params }) => {
  const list_page = useListAssignCategory({
    status: undefined,
    page: 1,
    limit: 1000,
    first: undefined,
  });
  return (
    <GridForm setParams={setParams} filter={filter} setFilter={setFilter}>
      <Dropdownz
        value={filter?.cb_id}
        onChange={(e) => setFilter({ ...filter, cb_id: e.target.value })}
        options={list_page}
        className=" col-6"
        placeholder="Chọn dự án"
        optionLabel="cb_title"
        optionValue="cb_id"
        filter
        style={{ lineHeight: "30px" }}
      ></Dropdownz>
      <Dropdownz
        value={filter?.extra_ids}
        onChange={(e) => setFilter({ ...filter, extra_ids: e.target.value })}
        options={[
          { id: 0, name: "Phân bổ" },
          { id: 1, name: "Ưu tiên" },
        ]}
        className=" col-3"
        placeholder="Chọn trạng thái"
        filter
        style={{ lineHeight: "30px" }}
      ></Dropdownz>
    </GridForm>
  );
};
const ProjectList = () => {
  const initParam = useGetParams();
  const [params, setParams] = useState(initParam);
  const [filter, setFilter] = useState(initParam);
  const [visible, setVisible] = useState(false);
  const [infos, setInfos] = useState();
  const navigate = useNavigate();
  const detail = useCategoryDetail({
    status: undefined,
    ...params,
    first: undefined,
  });
  const list_page = useListAssignCategory({
    status: undefined,
    ...params,
    first: undefined,
  });
  const totalRecords =
    useCountListAssignCategory({
      status: undefined,
      ...params,
      first: undefined,
    }) || 0;
  const [showDialog, setShowDialog] = useState(false);
  const [campaignName, setCampaignName] = useState();
  const handleShow = (page, id, category_id) => {
    setShowDialog(true);
    setCampaignName(page);
    setInfos({ ...infos, category_id: category_id, id: id });
  };
  const list_projectForm = useListProjectForm(params);
  const list_Updateproject = useListPrioritize(params);
  const handleData = () => {
    if (!infos?.category_id) return "Bạn chưa chọn dự án";
    return infos;
  };
  const handleDataProject = () => {
    if (!infos?.cb_update) return "Bạn chưa chọn dự án";
    return infos;
  };
  const handleReturn = () => {
    navigate("/page_list");
  };
  const [visibleUpdateProject, setVisibleUpdateProject] = useState(false);
  const handleUpdateProject = () => {
    setVisibleUpdateProject(true);
  };
  const handleDeleteProject = async (cb_delete) => {
    await deletePrioritizeProject({ cb_delete: cb_delete });
    setParams((pre) => {
      return { ...pre, render: !pre.render };
    });
  };
  const [visibleImport, setVisibleImport] = useState(false);
  const listPage = (category_id) => {
    const list = detail?.filter((e) => e.cb_id == category_id);
    return (
      <div>
        <ul>
          {list?.map((item, index) => (
            <li>{item?.page_name}</li>
          ))}
        </ul>
      </div>
    );
  };
  const deleteProject = (extra_ids, cb_delete) => {
    if (extra_ids) {
      return (
        <Button
          type="button"
          icon="pi pi-trash"
          rounded
          severity="danger"
          outlined
          className={"mr-1 mx-auto flex"}
          onClick={() => handleDeleteProject(cb_delete)}
        />
      );
    }
  };
  return (
    <div>
      {showDialog && (
        <FormUpdateDialog
          visible={showDialog}
          setVisible={setShowDialog}
          title="Phân chia dự án"
          checkId={Number(showDialog)}
          setParams={setParams}
          actions={{ update: updateProjectCampaign }}
          handleData={handleData}
        >
          <div className="grid grid-form" style={{ marginBottom: "20vh" }}>
            <div className="col-6">
              <InputForm label="Tên page" value={campaignName} readOnly />
            </div>
            <div className="col-6 ">
              <DropdownForm
                value={infos?.category_id}
                onChange={(e) =>
                  setInfos({ ...infos, category_id: e.target.value })
                }
                options={list_projectForm}
                optionLabel="cb_title"
                optionValue="cb_id"
                label="Dự án"
              />
            </div>
          </div>
        </FormUpdateDialog>
      )}
      <FormUpdateDialog
        visible={visibleUpdateProject}
        setVisible={setVisibleUpdateProject}
        title="Cập nhật các dự án ưu tiên phân bổ"
        checkId={Number(visibleUpdateProject)}
        setParams={setParams}
        actions={{ update: prioritizeProject }}
        handleData={handleDataProject}
        width={"1000px"}
      >
        <div
          className="flex mx-auto justify-content-center"
          style={{ marginBottom: "20vh" }}
        >
          <div className="w-8">
            <DropdownForm
              value={infos?.cb_update}
              onChange={(e) =>
                setInfos({ ...infos, cb_update: e.target.value })
              }
              options={list_Updateproject}
              optionLabel="cb_title"
              optionValue="cb_id"
              label="Dự án"
            />
          </div>
        </div>
      </FormUpdateDialog>
      {visibleImport && (
        <ImportService
          visible={visibleImport}
          setVisible={setVisibleImport}
          setParams={setParams}
          action={importQuestion}
          title={"lịch phân bổ"}
          type={"phanbo"}
        />
      )}

      <div className="card mx-auto ">
        <HeaderListForm title="Phân bổ lịch dự án" />
        <Header
          setParams={setParams}
          setFilter={setFilter}
          filter={filter}
          params={params}
        />
        <LoadDialog visible={visible} />
        <DataTablez
          title="phê duyệt"
          value={list_page}
          totalRecords={totalRecords}
          params={params}
          setParams={setParams}
          basePermissions={["import", "updateProject"]}
          headerInfo={{
            setVisibleImport: setVisibleImport,
          }}
          handleUpdateProject={handleUpdateProject}
        >
          <Columnz header="Tên dự án" field="cb_title" />
          <Columnz header="Page được phân bổ" body={(e) => listPage(e.cb_id)} />
          <Columnz
            body={(e) => (
              <Link to={`/page_list/detail/${e?.cb_id}`}>
                {" "}
                <Button
                  type="button"
                  icon="pi pi-calendar-clock"
                  rounded
                  outlined
                  className={"mr-1 mx-auto flex"}
                />
              </Link>
            )}
            header="Chi tiết lịch phân bổ"
          />
          <Columnz
            header="Gỡ dự án ưu tiên"
            body={(e) => deleteProject(e?.extra_ids, e?.cb_id)}
          />
        </DataTablez>
      </div>
    </div>
  );
};
export default ProjectList;
