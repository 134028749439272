import { useEffect, useState } from "react";
import {
  listLeadperDay,
  listLeadperDaySearch,
  listLeadperPb,
  listLeadperProject,
  listPbReport,
  staticLeadperDay,
  staticLeadperPb,
} from "../api";

export const useListPbReport = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await listPbReport({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListLeadperPb = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await listLeadperPb({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListLeadperDay = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await listLeadperDay({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListLeadperDaySearch = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await listLeadperDaySearch({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useStaticLeadperDay = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await staticLeadperDay({ status: 1, ...params });
    if (response?.status) setData(response?.data?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useStaticLeadperPb = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await staticLeadperPb({ status: 1, ...params });
    if (response?.status) setData(response?.data?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListProject = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await staticLeadperPb({ status: 1, ...params });
    if (response?.status) setData(response?.data?.list);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListPBLeadperDay = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await staticLeadperDay({ status: 1, ...params });
    if (response?.status) setData(response?.data?.list);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListLeadperProject = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await listLeadperProject({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
