import { useEffect, useState } from "react";
import {
  useCountListSheet,
  useGetListSheet,
  useListProjectForm,
} from "../utils";
import { useGetParams } from "../../../hooks/useGetParams";
import { Columnz, DataTablez, StatusBody } from "@/components/data_table";
import {
  Dropdownz,
  GridForm,
  HeaderListForm,
  Inputz,
} from "@/components/data_table/FormList";

import { LoadDialog } from "@/components/data_table/Dialogz";
import { Button } from "@/uiCore";
import {
  FormUpdateDialog,
  InputForm,
} from "@/components/data_table/FormUpdate";
import {
  insertNewSheet,
  removeSheet,
  updateActiveSheet,
  updateSheet,
  updateSheetDetail,
} from "../api";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setToast } from "@/redux/features";
import { listToast } from "@/constants";
const Header = ({ setParams, setFilter, filter, params }) => {
  const list_projectForm = useListProjectForm();

  return (
    <GridForm setParams={setParams} filter={filter} setFilter={setFilter}>
      <Inputz
        value={filter?.key_search}
        className="col-3"
        placeholder="Tìm kiếm theo tiêu đề"
        onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
      ></Inputz>
      <Dropdownz
        value={filter?.status}
        onChange={(e) => setFilter({ ...filter, status: e.target.value })}
        options={[
          { id: 1, name: "ACTIVE" },
          { id: 0, name: "INACTIVE" },
        ]}
        className=" col-4"
        placeholder="Chọn trạng thái"
        style={{ lineHeight: "30px" }}
      ></Dropdownz>
      <div className="col-2"></div>
    </GridForm>
  );
};
const SheetList = () => {
  const dispatch = useDispatch();
  const initParam = useGetParams();
  const [filter, setFilter] = useState();
  const [params, setParams] = useState(initParam);
  const [visible, setVisible] = useState(false);
  const [infos, setInfos] = useState();
  const list_sheet = useGetListSheet({
    status: undefined,
    ...params,
    first: undefined,
  });
  const totalRecords =
    useCountListSheet({
      status: undefined,
      ...params,
      first: undefined,
    }) || 0;
  const handleDataAdd = () => {
    if (!infos?.sheet_id) return "Bạn chưa nhập id sheet";
    return infos;
  };
  const handleDataUpdate = () => {
    if (!infos?.sheet_id) return "Bạn chưa nhập id sheet";
    return infos;
  };
  const [visibleUpdate, setVisibleUpdate] = useState(false);
  const [visibleAdd, setVisibleAdd] = useState(false);

  const accept = async (id) => {
    await updateSheetDetail({ id_form: id });
    setParams((pre) => ({ ...pre, render: !pre.render }));
    dispatch(
      setToast({
        ...listToast[0],
        detail: `Cập nhật dữ liệu thành công!`,
      })
    );
  };
  const confirm1 = (id) => {
    confirmDialog({
      message: "Xác nhận cập nhật thông tin form sheet này",
      header: "Xác nhận",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept: () => accept(id),
    });
  };
  const handleConfirm = () => {
    // setConfirmVisible(true);
    confirm1();
  };
  const handleUpdate = (e) => {
    setInfos({
      ...infos,
      department_id: e?.department_id,
      category_id: e?.category_id,
      sheet_id: e?.sheet_id,
      id: e?.id,
      campaign_name: e?.campaign_name,
      campaign_id: e?.campaign_id,
      website: e?.website !== "null" ? e?.website : "",
      landing_page: e?.landing_page !== "null" ? e?.landing_page : "",
    });
    setVisibleUpdate(true);
  };
  const ParseDate = (date) => {
    const date_only = String(date)?.substring(0, 10)?.split("-");
    return `${date_only[2]}-${date_only[1]}-${date_only[0]}`;
  };
  const ParseTime = (date) => {
    let dateValue = new Date(date);
    const time = `${dateValue?.getHours()}:${dateValue?.getMinutes()}:${dateValue?.getSeconds()}`;
    const date_only = String(date)?.substring(0, 10)?.split("-");
    return `${time} ${date_only[2]}-${date_only[1]}-${date_only[0]}`;
  };
  const onHidingAdd = () => {
    setInfos({});
    setVisibleAdd(false);
  };
  const onHidingUpdate = () => {
    setInfos({});
    setVisibleUpdate(false);
  };
  const handleAdd = () => {
    setInfos({});
    setVisibleAdd(true);
  };
  const handleDelete = async (e) => {
    await removeSheet(e);
  };
  const active = list_sheet?.find((e) => e?.status == 1);
  const status_active = Boolean(active);
  return (
    <div>
      <ConfirmDialog />
      <FormUpdateDialog
        visible={visibleAdd}
        setVisible={setVisibleAdd}
        title="Thêm mới Google Sheet"
        setParams={setParams}
        actions={{ add: insertNewSheet }}
        handleData={handleDataAdd}
        width={"1400px"}
        onHide={() => onHidingAdd()}
      >
        <div className="grid ">
          <div className="col-12">
            <InputForm
              value={infos?.sheet_id}
              onChange={(e) => setInfos({ ...infos, sheet_id: e.target.value })}
              label="Url Sheet (*)"
            />
          </div>
        </div>
      </FormUpdateDialog>
      <FormUpdateDialog
        visible={visibleUpdate}
        setVisible={setVisibleUpdate}
        title="Cập nhật URL"
        checkId={Number(visibleUpdate)}
        setParams={setParams}
        actions={{ update: updateSheet }}
        handleData={handleDataUpdate}
        width={"1400px"}
        onHide={() => onHidingUpdate()}
      >
        <div className="grid ">
          <div className="col-12">
            <InputForm
              value={infos?.sheet_id}
              onChange={(e) => setInfos({ ...infos, sheet_id: e.target.value })}
              label="Url Sheet (*)"
            />
          </div>
        </div>
      </FormUpdateDialog>
      <div className="card mx-auto ">
        <HeaderListForm title="Quản lý dữ liệu Google Sheet" />
        <div className="mb-3">
          <Button
            onClick={() => handleAdd(true)}
            icon="pi pi-plus"
            label="Thêm mới"
            size="small"
            raised
            type="button"
          />
        </div>
        <Header
          setParams={setParams}
          setFilter={setFilter}
          filter={filter}
          params={params}
        />
        <LoadDialog visible={visible} />
        <DataTablez
          title="phê duyệt"
          value={list_sheet}
          totalRecords={totalRecords}
          params={params}
          setParams={setParams}
          // actionsInfo={{ deleteAction: removeSheet }}
          // basePermissions={"delete"}
        >
          <Columnz
            header="Tiêu đề"
            body={(e) => (
              <a href={`${e?.sheet_id}`} target="_blank" rel="Sheet URL">
                {e?.title}
              </a>
            )}
          />
          <Columnz header="Số trang tính" field="number_sheet" />
          <Columnz header="Ngày tạo" body={(e) => ParseDate(e?.created_at)} />
          <Columnz
            header="Cập nhật gần nhất"
            body={(e) => ParseTime(e?.updated_at)}
          />
          <Columnz
            body={(e) => (
              <div className="flex flex-row justify-content-center">
                <Link to={`/sheet_list/detail/${e?.id}`}>
                  {" "}
                  <Button
                    type="button"
                    icon="pi pi-eye"
                    rounded
                    outlined
                    className={" mx-auto flex"}
                    severity={"warning"}
                  />
                </Link>
              </div>
            )}
            header="Chi tiết trang tính"
          />
          <Columnz
            body={(e) => (
              <div className="flex flex-row">
                <Button
                  onClick={() => handleUpdate(e)}
                  type="button"
                  icon="pi pi-wrench"
                  rounded
                  outlined
                  className={" mx-auto flex"}
                />
              </div>
            )}
            header="Set up URL"
          />
          <Columnz
            body={(e) => (
              <div className="flex flex-row">
                <Button
                  onClick={() => confirm1(e?.id)}
                  type="button"
                  icon="pi pi-sync"
                  rounded
                  outlined
                  className={" mx-auto flex"}
                  severity={"info"}
                />
              </div>
            )}
            header="Cập nhật sheet"
          />
          <Columnz
            header="Active"
            body={(e) =>
              StatusBody({
                e,
                isAllow: true,
                changeStatus: e?.status == 1 ? false : status_active,
                updateAction: updateActiveSheet,
                setParams: setParams,
                params: params,
              })
            }
          />
        </DataTablez>
      </div>
    </div>
  );
};
export default SheetList;
